<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          	<el-col :span="12">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{ $t("message.tracking") }} </div>
				</el-col>
		   </el-row>
			<el-row :gutter="10">
				<el-col :span="4">
              	<select-client
						:size="'large'"
						class="mm__custom__search__width w-100"
						:placeholder="$t('message.client')"
						:id="filterForm.client_id"
						v-model="filterForm.client_id"
						:create="false"
					>
					</select-client>
				</el-col>

				<el-col :span="4">
					 <select-deal
							:size="'large'"
							class="mm__custom__search__width w-100"
							:placeholder="$t('message.deal')"
							:id="filterForm.deal_id"
							@filterDealInput="filterDealInput"
							v-model="filterForm.deal_id"
						>
						</select-deal>
				</el-col>
				
				<el-col :span="6">
					<div class="d-flex" id="select_filials" v-if="!filterForm.deal_id">
						<select-from-filial v-model="filterForm.from_filial_id" :id="filterForm.from_filial_id" :placeholder="$t('message.from_where')" class="mm__custom__search__width" />
						<div class="select__reverse" @click="changeFilials()">
								<a>
									<span class="el-icon-sort"></span>
								</a>
						</div>
						<select-to-filial v-model="filterForm.to_filial_id" :id="filterForm.to_filial_id" :placeholder="$t('message.to_where')" class="mm__custom__search__width" />
					</div>
				</el-col>
				
				<el-col :span="6">
					<el-date-picker
						v-if="!filterForm.deal_id"
						class="w-100"
						v-model="filterForm.date_range"
						type="daterange"
						range-separator="-"
						:start-placeholder="$t('message.begin_date')"
						:end-placeholder="$t('message.end_date')"
						format="yyyy-MM-dd HH:mm"
						value-format="yyyy-MM-dd HH:mm"
						:default-time="['00:00:01', '23:59:59']">
					</el-date-picker>
				</el-col>

				<el-col :span="4">
					<el-input :placeholder="$t('message.tracking_code_deal')" v-model="filterForm.tracking_code" size="large"></el-input> 
				</el-col>

        </el-row>
      </div>
      <hr>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart" v-loading="loadingData"  :class="mode ? 'content__titleday' : 'content__titlenight'">
          <div class="d-flex">

            <div class="mm__aside__custom p-4">
              <h3> {{ $t('message.client_info') }} </h3>
              <el-row style="border-right: 2px solid gray;">
                <div class="dashboard__flex">
                  <div class="dashboard__photo">
                      <img :src="client.avatar && client.avatar[0] ? (client.image_url + '/' + client.avatar[0].path) : '/img/default-user-icon.png'" alt="">
                  </div>
                  <div class="dashboard__info">
                      <h5>{{ client.name }}</h5>
                      <div>
                          <p v-if="client.phone"><span class="dashboard__margin el-icon-phone"></span><span>{{ client.phone }}</span></p>
                          <p v-if="client.email"><span class="dashboard__margin el-icon-message"></span><span>{{ client.email ? client.email : ''}}</span></p>
                          <p v-if="client.country || client.city"><span class="dashboard__margin el-icon-location"></span><span>{{ client.city ? client.city.name + ',' : '' }} {{ client.country ? client.country.name : ''}}</span></p>
                      </div>
                  </div>
                </div>
                <el-col :span="24" v-if="client.location" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.location') }}: </span> {{ client.location}}
                </el-col>
                <el-col :span="24" v-if="client.type" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.type') }}: </span> {{ client.type ? client.type.name : '' }}
                </el-col>
                <el-col :span="24" v-if="client.gender" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.gender') }}: </span> {{ client.gender ? client.gender.name : '' }}
                </el-col>
                <el-col :span="24" v-if="client.inn" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.inn') }}: </span> {{ client.inn}}
                </el-col>
                <el-col :span="24" v-if="client.mfo" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.mfo') }}: </span> {{ client.mfo}}
                </el-col>
                <el-col :span="24" v-if="client.oked" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.oked') }}: </span> {{ client.oked}}
                </el-col>
                <el-col :span="24" v-if="client.okonx" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.okonx') }}: </span> {{ client.okonx}}
                </el-col>
                <el-col :span="24" v-if="client.total_amount" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.total_amount') }}: </span> {{ client.total_amount | formatMoney(1) }}
                </el-col>
                <el-col :span="24" v-if="client.paid_money" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.paid_money') }}: </span> {{ client.paid_money | formatMoney(1) }}
                </el-col>
                <el-col :span="24" v-if="client.remainder" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.remainder') }}: </span> 
                  <span :class="client.remainder < 0 ? 'mm_red' : 'mm_green'"> {{ client.remainder | formatMoney(1) }} </span>
                </el-col>
                <el-col :span="24" v-if="client.deal_count" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.deals') }}: </span> {{ client.deal_count }}
                </el-col>
                <el-col :span="24" v-if="client.task_count" class="mt-2">
                  <span class="mm__font__weight__custom">  {{ $t('message.tasks') }}: </span> {{ client.task_count }}
                </el-col>
              </el-row>
            </div>

            <div class="mm__main__custom p-4 mr-5">
              <h3> {{ $t('message.deal_info') }} №: {{ deal.id }} </h3>
              <el-row :gutter="20">
                <el-col :span="8" v-if="deal.from_filial">
                  <span class="mm__font__weight__custom">  {{ $t('message.from_where') }}: </span> {{ deal.from_filial ? deal.from_filial.name : '' }}
                </el-col>
                <el-col :span="8" v-if="deal.to_filial">
                  <span class="mm__font__weight__custom">  {{ $t('message.to_where') }}: </span> {{ deal.to_filial ? deal.to_filial.name : '' }}
                </el-col>
                <el-col :span="8" v-if="deal.directionTariff">
                  <span class="mm__font__weight__custom">  {{ $t('message.directionTariff') }}: </span> {{ deal.directionTariff ? deal.directionTariff.name : '' }}
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-3">
                <el-col :span="8" v-if="deal.begin_date">
                  <span class="mm__font__weight__custom">  {{ $t('message.date') }}: </span> {{ deal.begin_date }} - {{ deal.end_date}}
                </el-col>
                <el-col :span="8" v-if="deal.phone">
                  <span class="mm__font__weight__custom">  {{ $t('message.phone') }}: </span> {{ deal.phone }}
                </el-col>
                <el-col :span="8" v-if="deal.user">
                  <span class="mm__font__weight__custom">  {{ $t('message.manager') }}: </span> {{ deal.user ? deal.user.name : '' }}
                </el-col>
              </el-row>

              <div class="mt-5">
                <el-table
                  class="rounded-sm"
                  :data="dealProductsList"
                  border
                  style="width: 100%">

                  <el-table-column :label="$t('message.name')">
                    <template slot-scope="scope">
                      {{ scope.row.product ? scope.row.product.name : '' }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.weight_in_kg')">
                      <template slot-scope="scope">
                          {{ scope.row.weight }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.total_quantity')">
                      <template slot-scope="scope">
                          {{ scope.row.quantity }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.from_filial')">
                      <template slot-scope="scope">
                          {{ scope.row.from_filial_remainder }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.packing')">
                      <template slot-scope="scope">
                          {{ scope.row.packing }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.on_flight')">
                      <template slot-scope="scope">
                          {{ scope.row.on_flight }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.unpacking')">
                      <template slot-scope="scope">
                          {{ scope.row.unpacking }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.to_filial')">
                      <template slot-scope="scope">
                          {{ scope.row.to_filial_remainder }}
                      </template>
                  </el-table-column>

                  <el-table-column :label="$t('message.client')">
                      <template slot-scope="scope">
                          {{ scope.row.client_remainder }}
                      </template>
                  </el-table-column>
                </el-table>

                <div class="mt-5">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <h3>{{ $t('message.total') }} {{ deal.product_total | formatMoney(1) }}</h3>
                    </el-col>
                    <el-col :span="8">
                      <h3>{{ $t('message.total_money_of_incoming_product') }}: {{ deal.incoming_product_money | formatMoney(1) }}</h3>
                    </el-col>
                    <el-col :span="8">
                      <h3>{{ $t('message.paid_money') }}: {{ deal.paid_money | formatMoney(1) }}</h3>
                    </el-col>
                  </el-row>
                </div>
              </div>

            </div>
          </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import selectDeal from "@/components/inventory/select-deal";

export default {
    name: "trakingDeal",
    components: {
      selectFromFilial,
      selectToFilial,
      selectClient,
      selectDeal
    },

    data() {
        return {
          filterForm: {
            client_id: null,
            deal_id: null,
            date_range: [],
            from_filial_id: '',
            to_filial_id: '',
				    tracking_code: '',
          },
          dealProductsList: [],
          loadingData: false,
          oldSearchedDeals: [],
        };
    },
    watch: {
      filterForm: {
          handler: async function(newVal, oldVal) {
            await this.updateDealInventory(newVal);
            if(this.filterForm.deal_id){
              this.updateList(this.filterForm.deal_id);
            }else{
              this.dealProductsList = [];
              this.emptyDeal();
            }
            if(this.filterForm.client_id){
              this.updateClient(this.filterForm.client_id);
            }
            if(!this.filterForm.deal_id && !this.filterForm.client_id){
              this.emptyClient();
            }
            if (newVal.tracking_code.length >= 5) {
              let query = {tracking_code: newVal.tracking_code};
              if(newVal.tracking_code == oldVal.tracking_code){
                query = {tracking_code: newVal.tracking_code, deal_ids: this.oldSearchedDeals};
              }
              this.getDealIdByTrackingCode(query).then((res) => {
                if (res.deal_id) {
                  this.updateList(res.deal_id);
                  if(newVal.tracking_code != oldVal.tracking_code){
                    this.oldSearchedDeals=[];  
                  }
                  this.oldSearchedDeals.push(res.deal_id);
                };
              })
              .catch((err) => {console.log(err)});
            }
          },
          deep: true,
          immediate: true
      },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            dealProducts: "dealProducts/dealProducts",
            client: "clients/model",
            deal: "deals/model",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            showDealProducts: "dealProducts/show",
            showDeal: "deals/show",
            showClient: "clients/show",
            emptyClient: "clients/empty",
            emptyDeal: "deals/empty",
            updateDealInventory: 'deals/inventory',
				    getDealIdByTrackingCode: 'deals/getDealIdByTrackingCode'
        }),

        changeFilials() {
          if(this.filterForm.from_filial_id && this.filterForm.to_filial_id){
            let from = JSON.parse(JSON.stringify(this.filterForm.from_filial_id));
            let to = JSON.parse(JSON.stringify(this.filterForm.to_filial_id));
            this.$set(this.filterForm, 'from_filial_id', to);
            this.$set(this.filterForm, 'to_filial_id', from);
          }
        },
        filterDealInput(val){
          const query = {...this.filterForm, id: val};
          this.updateDealInventory(query);
        },

        updateClient(client_id){
          this.loadingData = true;    
          this.showClient(client_id).then(res => {   
              this.loadingData = false              
          }).catch(err => {
              this.loadingData = false
          });
        },

        async updateList(query){
          this.loadingData = true;
          if(this.currencies && this.currencies.length === 0){
            await this.updateCurrencyInventory();
          }
          await this.showDeal(query).then(res => {   
              if(!this.client.id || this.deal.client_id !== this.client.id){
                  this.updateClient(this.deal.client_id);
              }
            }).catch(err => {
            });

          await this.showDealProducts({id: query})
              .then((res) => {
                this.dealProductsList = JSON.parse(JSON.stringify(this.dealProducts));
                this.updateProducts = true;
                this.loadingData = false;
              })
              .catch((err) => {
                this.loadingData = false;
              });
        }
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('sources/EMPTY_LIST');
        next()
    },
};
</script>
<style>
 .mm__custom__search__width {
    width: 200px;
 }
 .mm__aside__custom {
    width: 25%;
 }
 .mm__main__custom {
    width: 75%;
 }

 .mm__font__weight__custom {
    font-weight: 600
 }

 .dashboard__flex {
    display: flex;
    flex-direction: row;
}
.dashboard__photo img  {
    width: 70px;
    height: 70px;
    object-fit: cover;
    /* border-radius: 50%;  */
}
.dashboard__info h5 {
    margin: 0;
    font-size: 16px;
}
.dashboard__info {
    margin-left: 20px;
}
.dashboard__info p {
    margin: 4px 0;
    font-size: 14px;
}
.mm_red {
  color: rgb(189, 38, 38);
}
.mm_green {
  color: rgb(38, 189, 88);
}
</style>
